<template>
    <phi-page class="page">
        <div slot="toolbar" class="page-toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1></h1>
        </div>

        <section class="post-stubs seen" v-show="seen.length">
			<label>{{ $t('adj.read.plural') }}</label>

			<div class="phi-card">
				<div
					v-for="stub in seen"
					:key="stub.id"
					class="phi-media person"
					@click="toggleUserInfo(stub)"
				>
					<div class="phi-media-figure phi-avatar">
						<img :src="stub.person.avatar" :alt="stub.person.firstName">
					</div>
					<div class="phi-media-body">
						<h1>{{stub.person.lastName}}, {{stub.person.firstName}}</h1>
						<div class="read-date read">
							<i class="fa fa-check-circle"></i>
							<span>{{ $date(stub.readDate, "time") }}</span>
						</div>
					</div>
				</div>
			</div>
        </section>

        <section class="post-stubs unseen" v-show="unseen.length">
			<label>{{ $t('adj.unread.plural') }}</label>
			<div class="phi-card">
				<div
					v-for="stub in unseen"
					:key="stub.id"
					class="phi-media person"
					@click="toggleUserInfo(stub)"
				>
					<div class="phi-media-figure phi-avatar">
						<img :src="stub.person.avatar" :alt="stub.person.firstName">
					</div>
					<div class="phi-media-body">
						<h1>{{stub.person.lastName}}, {{stub.person.firstName}}</h1>
						<div class="read-date unread">
							<i class="fa fa-circle"></i>
							<span>{{ $t('adj.unread.singular') }}</span>
						</div>
					</div>
				</div>
			</div>
        </section>

    </phi-page>
</template>

<script>
import app from '../../store/app.js';
import PhiPersonInscriptions from '../../components/Phi/Person/Inscriptions.vue';

export default {
	name: "post-stubs",
	components: { PhiPersonInscriptions},

	data() {
		return {
			stubs: app.api.collection(`/posts/${this.$route.params.postId}/stubs`)
		}
	},

	methods: {
		toggleUserInfo(stub) {
			if (!this.stubs.hasTag(stub, 'hasInfo')) {
				this.stubs.tag(stub, 'hasInfo');
				return;
			}
			this.stubs.toggleTag(stub, 'expanded');
		}
	},

	computed: {
		seen() {
			return this.stubs.items.filter(stub => !!stub.readDate);
		},

		unseen() {
			return this.stubs.items.filter(stub => !stub.readDate);
		}
	},

	beforeRouteEnter(to, from, next) {
		var stubs = app.api.collection(`/posts/${to.params.postId}/stubs`);
		stubs.fetch({all:1})
			.then(() => {
				next(vm => {
					vm.stubs = stubs;
				});
			});
	}

}
</script>

<style scoped lang="scss">
.page {
	background-color: #1C89B8 !important;
}

.page-toolbar {
    color: #fff;
}

.person {
	cursor: pointer;

	.phi-media {
		padding: 0;
	}

	h1 {
		font-size: 16px;
	}

	.read-date {
		color: #777;
		font-size: 0.9em;
        margin-top: 3px;

        &.read {
            i {
                color: green;
            }
        }
	}
}

.phi-person-inscriptions {
	margin-top: 4px;
	padding: 6px 12px;
	font-size: 0.8em;
	border-radius: 4px;
	background-color: #fff;
}

section {
	margin: 12px 0;

	label {
		display: block;
		font-size: 12px;
		text-transform: uppercase;
		color: #666;
		padding: 4px 0;
	}
}

</style>